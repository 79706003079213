import IndexedDbHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';

import type * as AppResourceStateModule from 'o365.pwa.declaration.shared.dexie.objectStores.AppResourceState.d.ts';

export class AppResourceState implements AppResourceStateModule.AppResourceState {
    static objectStoreDexieSchema: string = "&[appId+id+scope], appId";

    appId!: string;
    id!: string;
    scope!: string;
    relativeRoot!: string;
    url!: string;

    get primaryKey(): string {
        return `${this.appId} - ${this.id} - ${this.scope}`;
    }

    constructor(appId: string, id: string, scope: string, relativeRoot: string, url: string) {
        this.appId = appId;
        this.id = id;
        this.scope = scope;
        this.relativeRoot = relativeRoot;
        this.url = url;
    }

    public async save(): Promise<void> {
        await IndexedDbHandler.updateAppResourceState(this);
    }

    public async delete(): Promise<void> {
        await IndexedDbHandler.deleteAppResourceState(this);
    }

    public async forceReload(): Promise<AppResourceState | null> {
        return await IndexedDbHandler.getAppResourceStateFromIndexedDB(this.appId, this.id);
    }
}

export default AppResourceState;
